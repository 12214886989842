import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import UserLinks from "../UserLinks";
import config from "../../../data/SiteConfig";
import "./About.scss";
import cover from "../../../static/assets/tgcover1.png"

console.log(cover) 

class About extends Component {
  render() {
    return (
      <div className="about-container md-grid mobile-fix">
        <Card className="md-grid md-cell--8">
          <div className="about-wrapper">
            <img
              src={cover}
              width="60%"
              //className="about-img"
              //alt={config.userName}
            />
            <CardText>
              <p className="about-text md-body-1">Dette er hjemmesiden til boken <i>Tempelgeometri</i> av Skage Hansen. Boken består av to deler: <i>Del 1</i> inneholder over 200 geometrioppgaver, mens <i>Del 2</i> presenter utførlige løsningsforslag til alle disse oppgavene. Boken kan <a href="http://getsmart.no/no/products/73">bestilles her</a>.</p>
<p className="about-text md-body-1"> Med over 200 oppgaver er det naturlig at noen av løsningsforslagene kan forbedres. Når vi oppdager slike tilfeller, vil disse publiserses på denne nettsiden. Andre ganger vil vi kanskje også legge ut noen nye utfordringer, så for den geometri-interesserte er det bare å følge med!</p>

<p className="about-text md-body-1"> For rettelser til tekst eller figurer, kontakt <br/> <i className="fa fa-envelope"></i> sindre.heggen@gmail.com <br/>Kontakt samme adresse hvis du har ønske om å publisere et løsningsforslag på denne siden.</p> <p> For andre spørsmål, kontakt <br/><i className="fa fa-envelope"></i> post@gettingbetter.no</p>
            </CardText>
            <UserLinks labeled config={config} />
          </div>
        </Card>
      </div>
    );
  }
}

export default About;
